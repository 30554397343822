import {
    CategoryPaginationLink as SourceCategoryPaginationLink
} from 'SourceComponent/CategoryPaginationLink/CategoryPaginationLink.component';

import './CategoryPaginationLink.style';

/** @namespace Scandipwa/Component/CategoryPaginationLink/Component */
export class CategoryPaginationLinkComponent extends SourceCategoryPaginationLink {
    // TODO implement logic
}

export default CategoryPaginationLinkComponent;
